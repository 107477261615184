module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5LCJPPV","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"spirable","short_name":"starter","start_url":"/","background_color":"#e31837","theme_color":"#e31837","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"31e778cf45226fa7c2c00528c8a7837a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.spirable.and.together.agency/wordpress/graphql","presets":null,"verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
