/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

require('./src/css/index.css');
require('./src/css/webfonts.css');

const {Utm} = require('./src/helpers/utm');

exports.onClientEntry = () => {
  console.log("We've started!")
  const utm = new Utm({
    // domain: 'spirable.and.together.agency',  // support subdomain
    expires: 365,
  })

  utm.store()  // simply store everything from window.location to cookies
  // console.log(utm.get());
}